import {SlidePanelComponent} from './slide-panel/slide-panel.component';
import {SlidePanelConfig, SlidePanelSize, SlidePanelVerticalAlign} from '../../definitions/definitions-base';
import {ComponentRef, TemplateRef, Type} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TaskInit} from '../../@core/ccm.service';

export interface PageThroughItem {
    id: number;
    secondaryId?: number | string;
    active?: boolean;
}

export interface SlidePanelOptions {
    title?: string;
    info?: string;
    canDouble?: boolean;
    size?: SlidePanelSize;
    verticalAlign?: SlidePanelVerticalAlign;
    customMarkup?: boolean;
    footerContent?: any;
    hasPrevFn?: () => boolean;
    hasNextFn?: () => boolean;
    prevFn?: () => void;
    nextFn?: () => void;
    showFab?: boolean;
    nextPrevBtnText?: string[];
    actionIcons?: ActionIcon[];
    selectedTab?: string;
    getPatientId?: () => number;
    getNoteData?: () => Partial<TaskInit>;
    openCallback?: () => void;
    componentInputs?: any;
    listenEscapeEvent?: boolean;
    disableChangeDetection?: boolean;
    templateContext?: any;
}

export interface SlidePanelArguments<T = any> extends SlidePanelOptions {
    navigationId?: number;
    activatedRoute?: ActivatedRoute;
    url?: string;
    component?: Type<T>;
    templateRef?: TemplateRef<any>;
    defRootUrl?: string;

    id?: number;
    config?: SlidePanelConfig;
    pageThrough?: PageThroughItem[];
    pagingSecondaryId?: number | string;
}

export interface ActionIcon {
    icon: string;
    action: (id) => any;
    tooltip?: string;
}

export class SlidePanel<T = any> implements SlidePanelArguments<T> {
    navigationId?: number;
    activatedRoute?: ActivatedRoute;
    url?: string;
    component?: Type<T>;
    componentRef?: ComponentRef<T>;
    templateRef?: TemplateRef<any>;
    panelComponent?: SlidePanelComponent;

    id?: number;
    config?: SlidePanelConfig;
    pageThrough?: PageThroughItem[];
    pagingSecondaryId?: number | string;

    title?: string;
    info?: string;
    canDouble?: boolean;
    size?: SlidePanelSize;
    verticalAlign?: SlidePanelVerticalAlign;
    customMarkup?: boolean;
    footerContent?: any;
    hasPrevFn = () => false;
    hasNextFn = () => false;
    prevFn?: () => void;
    nextFn?: () => void;
    showFab?: boolean;
    nextPrevBtnText?: string[];
    actionIcons?: ActionIcon[];
    selectedTab?: string;
    getPatientId?: () => number;
    getNoteData?: () => Partial<TaskInit>;
    openCallback?: () => void;
    componentInputs?: any;
    listenEscapeEvent?: boolean;
    disableChangeDetection?: boolean;
    templateContext?: any;

    private _defRootUrl: string;

    get defRootUrl() {
        return this._defRootUrl ?? this.url?.substr(0, this.url.lastIndexOf('/')) ?? '';
    }

    set defRootUrl(x) {
        this._defRootUrl = x;
    }

    get isBst() {
        const comp: any = this.componentRef?.instance;
        return comp?.isBst || comp?.patient?.isBst;
    }

    get featureIndication(): 'bst' | 'arsana' {
        if (this.isBst) return 'bst';
        const comp: any = this.componentRef?.instance;
        return comp?.patient?.customer === null ? 'arsana' : null;
    }

    constructor(x: SlidePanelArguments<T>) {
        Object.assign(this, x);
    }
}

export interface SlidePanelComponentInstance {
    slidePanelDetailView?: boolean;
}
