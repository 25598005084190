import {EnvironmentInjector, Injectable, Injector, Type, ViewContainerRef} from '@angular/core';
import {NavigationMerged} from './slide-panel-router-outlet';
import {ActivatedRoute, ActivatedRouteSnapshot, DetachedRouteHandle, LoadChildrenCallback} from '@angular/router';
import {SlidePanel, SlidePanelArguments, SlidePanelOptions} from './slide-panel';
import {ReplaySubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SlidePanelStackController {
    slidePanelOptions: SlidePanelOptions;
    panelStack: SlidePanel[] = [];
    rootUrl: string;
    backgroundRoute: {route: ActivatedRouteSnapshot; detachedTree: DetachedRouteHandle};
    skipNavigation = false;

    viewContainerRef: ViewContainerRef;

    readonly panelChange$ = new Subject<{event: 'push' | 'pop' | 'empty'}>();
    readonly activePanel$ = new ReplaySubject<SlidePanel>(1);

    private _activatedRoute: ActivatedRoute;

    get activatedRoute() {
        return this._activatedRoute;
    }

    get activePanel() {
        return this.panelStack[this.panelStack.length - 1];
    }

    constructor() {
        this.panelChange$.subscribe(() => this.activePanel$.next(this.activePanel));
    }

    handleNavigation(nav: NavigationMerged) {
        // skip duplicate navigation especially on first load
        if (this.activePanel?.navigationId === nav.id) return;

        if (!nav.snapshot?.data?.slidePanel) {
            this.empty();
            return;
        }

        if (nav.restoredState) {
            const ppi = this.panelStack.findIndex(x => x.navigationId === nav.restoredState.navigationId && x.url === nav.url);
            if (ppi > -1) {
                this.panelStack = this.panelStack.slice(0, ppi + 1);
                this.panelStack[ppi].navigationId = nav.id;
                this.panelStack[ppi].activatedRoute = nav.activatedRoute;
                this._activatedRoute = nav.activatedRoute;
                this.panelChange$.next({event: 'pop'});
                return;
            }
        }

        if (!nav.snapshot.routeConfig.component) throw new Error('No component found');

        this.pushComponentPanel({
            navigationId: nav.id,
            activatedRoute: nav.activatedRoute,
            url: nav.url,
            component: nav.snapshot.routeConfig.component,
            defRootUrl: nav.snapshot.data.defRootUrl,
            config: nav.snapshot.data.slidePanelConfig,
            id: Number.isInteger(+nav.snapshot.params.id) ? +nav.snapshot.params.id : nav.snapshot.params.id,
            ...(nav.snapshot.data.slidePanelOptions as SlidePanelOptions || {}),
            ...(this.slidePanelOptions || {}),
            pageThrough: nav.extras?.state?.pageThrough,
            pagingSecondaryId: nav.extras?.state?.pagingSecondaryId,
        }, nav.viewContainerRef, nav.injector, nav.environmentInjector);

        this._activatedRoute = nav.activatedRoute;
        this.slidePanelOptions = null;
    }

    push(slidePanel: SlidePanelArguments) {
        this.panelStack.push(slidePanel instanceof SlidePanel ? slidePanel : new SlidePanel(slidePanel));
        this.panelChange$.next({event: 'push'});
    }

    pop() {
        if (this.panelStack.length < 2) return this.empty();

        this.panelStack.pop();
        this.panelChange$.next({event: 'pop'});
    }

    empty() {
        this.panelStack = [];
        this.rootUrl = null;
        this._activatedRoute = null;
        this.panelChange$.next({event: 'empty'});
    }

    async loadAndPushComponentPanel(slidePanelArguments: SlidePanelArguments, loadComponent: () => Promise<Type<any>>, loadChildren?: LoadChildrenCallback) {
        if (loadChildren) await loadChildren();

        this.pushComponentPanel({
            ...slidePanelArguments,
            component: await loadComponent(),
        }, this.viewContainerRef, this.viewContainerRef.injector);
    }

    pushComponentPanel(slidePanel: SlidePanelArguments, viewContainerRef: ViewContainerRef, injector: Injector, environmentInjector?: EnvironmentInjector) {
        const panel = this.createComponentPanel(slidePanel, viewContainerRef, injector, environmentInjector);
        this.push(panel);
        return panel;
    }

    createComponentPanel(slidePanel: SlidePanelArguments, viewContainerRef: ViewContainerRef, injector: Injector, environmentInjector?: EnvironmentInjector) {
        const panel = new SlidePanel(slidePanel);
        panel.componentRef = this.createComponent(panel.component, viewContainerRef, injector, environmentInjector);
        this.setComponentProperties(panel);
        return panel;
    }

    createComponent<T>(component: Type<T>, viewContainerRef: ViewContainerRef, injector: Injector, environmentInjector?: EnvironmentInjector) {
        return viewContainerRef.createComponent(component, {injector, environmentInjector});
    }

    setComponentProperties(panel: SlidePanel, componentInstance = panel.componentRef.instance) {
        if (panel.id) componentInstance.id = panel.id;
        if (panel.config) Object.assign(componentInstance, panel.config);
        if (panel.componentInputs) Object.assign(componentInstance, panel.componentInputs);
        componentInstance.slidePanelDetailView = true;
    }
}
